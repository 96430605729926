import React from "react"
const slugify = require("./slugify")

const htmlSerializer = (type, element) => {
  if (type === "heading2") {
    const heading2Html = `<h2 id="${slugify(element.text)}">${
      element.text
    }</h2>`
    return <div dangerouslySetInnerHTML={{ __html: heading2Html }} />
  }
  if (type === "heading3") {
    const heading3Html = `<h3 id="${slugify(element.text)}">${
      element.text
    }</h3>`
    return <div dangerouslySetInnerHTML={{ __html: heading3Html }} />
  }
  return null
}

export default htmlSerializer
