import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Icon } from "../Icon"

const options = [
  {label: "Retailer", name: "Retailer"},
  {label: "Product manufacturer", name: "Product manufacturer"},
  {label: "Solution provider", name: "Solution provider"},
  {label: "Academic/student", name: "Academic/student"},
  {label: "Other", name: "Other"},
]
export const SelectField = ({ field, form: { errors, touched, setFieldValue }, ...props }) => {
  const errorMessage = touched[field.name] && errors[field.name]
  const [currentValue, setCurrentValue] = useState('Retailer')
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const onMenuOpen = () => {
    setMenuIsOpen(!menuIsOpen)
  }
  const onClickItem = (label) => {
    setCurrentValue(label)
    setMenuIsOpen(false)
    setFieldValue(field.name, label)
  }

  useEffect(() => {
    if(!props.sidebarOpen) setMenuIsOpen(false)

  }, [props.sidebarOpen])

  return (
    <div>
      <div {...field} {...props}>
        <MenuWrapper menuIsOpen={menuIsOpen}>
          <button type="button" onClick={onMenuOpen}>
            {currentValue}
            <Icon type={"ARROW"}/>
          </button>
          <ul>
            {options.map((el, i) => {
              return (
                <li onClick={() => onClickItem(el.label)} key={i}>{el.label}</li>
              )
            })}
          </ul>
        </MenuWrapper>
        {/*<select {...field} {...props} />*/}
        {errorMessage && <Text variant="error">{errorMessage}</Text>}
      </div>
    </div>
  )
}

const MenuWrapper = styled.div`
  margin-top: 16px;
  position: relative!important;
  border: none!important;
  width: 85%;
  max-height: 46px;
  margin-right: 15px!important;
  
  a {
    color: white!important;
    font-size: 16px!important;
    border: none!important;
    margin: 0!important;
    position: relative!important;
    text-align: left;
    padding: 8px;
    height: 46px;
    cursor: pointer;    
    background-color: ${({theme}) => theme.colors.primary_color}!important;
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    svg {
      display: block;
      transform: ${props => props.menuIsOpen ? "rotate(180deg)" : "rotate(0)"};;
    }
  }
  ul {
    margin-right: 15px!important;
    background-color: ${({theme}) => theme.colors.primary_color};
    position: absolute;
    content: '';
    top: 105%;
    left: 0;
    width: 85%;
    overflow: hidden;
    transition: ${({theme}) => theme.durations.transition};
    opacity: ${props => props.menuIsOpen ? "1" : "0"};
    visibility: ${props => props.menuIsOpen ? "visible" : "hidden"};
    max-height: ${props => props.menuIsOpen ? "300px" : "0"};
    
    li {
      width: 100%;
      padding: 8px;
      font-size: 16px!important;
      position: relative;
      cursor: pointer;
      
      &:hover {
        background-color: ${({theme}) => theme.colors.secondary_color};
        
        &::before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          height: 100%;
          width: 2px;
          background-color: white;
        }
      }
    }
  }
`
const Text = styled.p`
  color: white;
  margin-top: 11px;
  font-size: 20px!important;
`
