import React, { useRef, useState } from "react"
import slugify from "../../utils/slugify"

import styled from "styled-components"
import { useOnClickOutside } from "../../hooks/useOnClickOutside"

const TOC = ({ data }) => {
  const [currentValue, setCurrentValue] = useState("Foreword")
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const popupRef = useRef(null)

  const onTogglePopup = () => {
    setIsPopupOpen(!isPopupOpen)
  }
  useOnClickOutside(popupRef, setIsPopupOpen)

  const handleValue = value => {
    setCurrentValue(value)
    setIsPopupOpen(false)
  }

  return (
    <LinksWrap>
      <h4>Table of Contents:</h4>
      <Links ref={popupRef} isPopupOpen={isPopupOpen}>
        <ButtonWrap isPopupOpen={isPopupOpen} onClick={onTogglePopup}>
          <button type={"button"}>{currentValue}</button>
        </ButtonWrap>
        <ul>
          {data.map((item, i) => (
            <li
              key={i}
              onClick={() => handleValue(item.text)}
              className={item.type === "heading3" ? "heading3" : "heading2"}
            >
              {item.type === "heading3" ? "- " : ""}
              <a href={`#${slugify(item.text)}`}>{item.text}</a>
            </li>
          ))}
        </ul>
      </Links>
    </LinksWrap>
  )
}

export default TOC

const LinksWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;

  @media (min-width: 577px) {
    margin-bottom: 0;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: bold;
    color: rgb(42,149,191);

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 20px;
    }
  }
`
const ButtonWrap = styled.div`
  cursor: pointer;

  &:after {
    content: "";
    display: inline-block;
    margin-left: 5px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.9834 1.99996L11.9917 11.9917L1.99993 1.99997' stroke='white' stroke-width='3.07438'/%3E%3C/svg%3E%0A");
    background-size: contain;
    background-repeat: no-repeat;
    width: 15px;
    height: 10px;
    transform: ${props => (props.isPopupOpen ? "rotate(180deg)" : "rotate(0)")};
  }
  button {
    padding: 8px 0;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    border: none;
    background: transparent;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 145px;

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 20px;
      max-width: 300px;
    }
  }
`
const Links = styled.div`
  list-style: none;
  padding: 0 0 0 10px;
  position: relative;
  z-index: 20;
  width: max-content;

  ul {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: -60%;
    background-color: #2a95bf;
    border: none;
    max-height: 230px;
    padding: 0;
    overflow: auto;
    width: 230px;
    display: ${props => (props.isPopupOpen ? "block" : "none")};

    @media (min-width: 480px) {
      left: 0;
    }
  }

  li {
    display: flex;
    align-items: center;
    padding: 8px;
    position: relative;

    &:hover {
      background-color: #005475;

      &:before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 2px;
        background: #fff;
      }
    }

    a {
      color: #fff;
      font-size: 14px;
      display: block;

      @media (min-width: 480px) {
        font-size: 16px;
      }
    }

    &.heading3 {
      a {
        font-size: 14px;
        margin-left: 4px;
      }
    }
  }
`
