import React from "react"
import styled from "styled-components"

export const InputField = ({ field, form: { errors, touched }, ...props }) => {
  const errorMessage = touched[field.name] && errors[field.name]
  return (
    <div>
      <Input {...field} {...props} />
      {errorMessage && <Text variant="error">{errorMessage}</Text>}
    </div>
  )
}

const Input = styled.input`
  color: rgb(128,170,186);
  font-size: 16px!important;
  
  &::placeholder {
    color: rgb(128,170,186);
  }
`
const Text = styled.label`
  color: rgb(42,149,191);
  margin-top: 5px;
  display: block;
`
