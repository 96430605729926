import React, { useState } from "react"
import Sidebar from "react-sidebar"
import styled from "styled-components"
import fileDownload from "js-file-download"
import Axios from "axios"
import { Formik, Field } from "formik"
import * as Yup from "yup"
import { InputField, SelectField } from "../fields"
import { useNoScroll } from "../../hooks/useNoScroll"

const ContactFormSchema = Yup.object().shape({
  organisation: Yup.string()
    .min(1, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  industry: Yup.string()
    .min(1, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
})

const SideBarContent = ({
  formId,
  dataLayerEvent,
  pageURL,
  downloadURL,
  sidebarOpen,
  onSetSidebarOpen,
}) => {
  const [working, setWorking] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  useNoScroll(sidebarOpen)

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleDownload = () => {
    console.log("Download Url in after button click", downloadURL.url)
    const filePath = downloadURL.url.replace(/^.*\/\/[^\/]+/, "")
    var filename = downloadURL.url.replace(/^.*[\\\/]/, "")
    console.log("Download Url in after filePath", filePath)
    Axios.get(downloadURL.url, {
      responseType: "blob",
    }).then(res => {
      fileDownload(res.data, filename)
    })
  }
  return (
    <SideBarMainContent>
      <CloseBtn onClick={() => onSetSidebarOpen(false)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentcolor"
          viewBox="0 0 24 24"
        >
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
        </svg>
      </CloseBtn>
      {success ? (
        <>
          <Text variant="contact.name">
            Your message has been sent, we will be in contact shortly.
          </Text>
          {downloadURL.url && (
            <>
              <Button
                type="primary"
                variant="primary"
                href={downloadURL.url}
                download={downloadURL.url.split("/").pop()}
                onClick={handleDownload}
              >
                Download
              </Button>
            </>
          )}
        </>
      ) : (
        <Formik
          initialValues={{
            organisation: "",
            name: "",
            email: "",
            industry: "",
          }}
          validationSchema={ContactFormSchema}
          onSubmit={async values => {
            setWorking(true)
            fetch("/", {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: encode({
                "form-name": "contact",
                ...values,
                pageURL,
              }),
            })
              .then(() => {
                if (dataLayerEvent && typeof window !== "undefined") {
                  let dataLayer = (window.dataLayer = window.dataLayer || [])
                  dataLayer.push(dataLayerEvent)
                }
                setWorking(false)
                setSuccess(true)
                handleDownload()
              })
              .catch(error => {
                setWorking(false)
                setErrorMessage(`We're sorry but something went wrong`)
              })
          }}
        >
          {({ handleSubmit }) => (
            <form
              id={formId}
              onSubmit={handleSubmit}
              name="contact"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              sx={{ width: "100%" }}
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <input type="hidden" name="page" />

              <Field
                id="name"
                name="name"
                type="text"
                placeholder="Name"
                component={InputField}
                className="inputwrap"
              />

              <Field
                id="organisation"
                name="organisation"
                type="text"
                placeholder="Organisation"
                component={InputField}
                className="inputwrap"
              />
              <Field
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                component={InputField}
                className="inputwrap"
              />
              <Field
                  name="industry"
                  component={SelectField}
                  className="inputwrapselect"
                  sidebarOpen={sidebarOpen}
              >
              </Field>
              {errorMessage && <p>{errorMessage}</p>}
              {working ? (
                <Spinner variant="styles.spinner" />
              ) : (
                <Button type="submit" variant="outline" disabled={working}>
                  Send
                </Button>
              )}
            </form>
          )}
        </Formik>
      )}
    </SideBarMainContent>
  )
}
const ResearchSidebar = ({ formId, dataLayerEvent, pageURL, downloadURL, onSetSidebarOpen, sidebarOpen }) => {

  return (
    <SideBarContainer>
      <Sidebar
        sidebar={
          <>
            <SideBarContent
              formId={formId}
              dataLayerEvent={dataLayerEvent}
              downloadURL={downloadURL}
              pageURL={pageURL}
              sidebarOpen={sidebarOpen}
              onSetSidebarOpen={onSetSidebarOpen}

            />
          </>
        }
        open={sidebarOpen}
        onSetOpen={onSetSidebarOpen}
        pullRight={true}
      >
        <></>
      </Sidebar>
    </SideBarContainer>
  )
}

export default ResearchSidebar

const SelectFieldWrapper = styled.div`
  
`

const SideBarContainer = styled.div`
  div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  div:nth-child(1)>div:nth-child(1) {
    border-left: .5px solid #2A95BF;
    width: 418px !important;
    max-width: 100% !important;
    overflow: visible !important;
    z-index: 1000 !important;
    background: #005475;
    color: white;
    font-size: 18px !important;
    position: fixed !important;
  }
  div:nth-child(2) {
    z-index: 100!important;
    background-color: rgba(0, 0, 0, .8)!important;
  }
  div:nth-child(3) {
    display: none;
  }
`

const SideBarMainContent = styled.div`
  margin: 0px !important;
  padding: 0px 32px !important;
  overflow: hidden;
  @media (max-width: 400px) {
    padding: 0px 20px !important;
  }
  input, label {
    font-size: 20px!important;
  }
  input::placeholder {
    font-size: 20px!important;
  }
`
const Spinner = styled.div``
const CloseBtn = styled.span`
  width: 100%;
  cursor: pointer;
  display: flex !important;
  justify-content: flex-end !important;
  color: white;
`
const Text = styled.div`
  display: inline-block;
  margin-top: 64px;
`
const Button = styled.button`
  font-size: 24px;
  line-height: 26px;
  color: white!important;
  font-weight: 400!important;
  border-radius: 0;
  border: 3px solid ${({theme}) => theme.colors.primary_color}!important;
  cursor: pointer;
  width: 100%;
  padding: 12px 16px;
  display: inline-block;
  text-transform: uppercase;
  margin-top: 20px;
  transition: ${({theme}) => theme.durations.transition};
  
  &:hover {
    background: ${({theme}) => theme.colors.primary_color}!important;
    transition: ${({theme}) => theme.durations.transition};
  }
`
