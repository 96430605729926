import React from "react"
import PropTypes from "prop-types"

import Research from "./Research"

const ResearchData = ({ document, toc, location }) => {
  const {
    title,
    cover,
    subtitle,
    content,
    category,
    authors_box_title: authorsBoxTitle,
    grant_providers_box_title: grantProvidersBoxTitle,
    related_papers_box_title: relatedPapersToxTitle,
    authors: authorsRawData,
    grant_providers: grantProvidersRawData,
    related_papers: relatedPapersRawData,
    file,
    body,
  } = document
  const authors = authorsRawData
    .filter(item => item.author.document != null)
    .map(item => ({
      ...item.author.document.data,
      linkURL: { type: item.author.type, uid: item.author.uid },
    }))
  const grantProviders = grantProvidersRawData
    .filter(item => item.organisation.document != null)
    .map(item => item.organisation.document.data)
  const relatedPapers = relatedPapersRawData.map(item => item.paper)

  return (
    <Research
      title={title}
      cover={cover}
      subtitle={subtitle}
      content={content}
      authorsBoxTitle={authorsBoxTitle}
      grantProvidersBoxTitle={grantProvidersBoxTitle}
      relatedPapersToxTitle={relatedPapersToxTitle}
      authors={authors}
      grantProviders={grantProviders}
      relatedPapers={relatedPapers}
      toc={toc}
      file={file}
      abstract={body}
      category={category}
      location={location}
    />
  )
}

ResearchData.propTypes = {
  document: PropTypes.object.isRequired,
  toc: PropTypes.array.isRequired,
}

export default ResearchData
