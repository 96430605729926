import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components";

const Abstract = ({ abstract, selectedLang }) => {
  const { EnglishFlag, FrenchFlag, SpanishFlag, GermanFlag, DutchFlag } =
    useStaticQuery(
      graphql`
        query {
          EnglishFlag: file(relativePath: { eq: "flags/english.png" }) {
            childImageSharp {
              gatsbyImageData(width: 40, quality: 100)
            }
          }
          FrenchFlag: file(relativePath: { eq: "flags/french.png" }) {
            childImageSharp {
              gatsbyImageData(width: 40, quality: 100)
            }
          }
          SpanishFlag: file(relativePath: { eq: "flags/spanish.png" }) {
            childImageSharp {
              gatsbyImageData(width: 40, quality: 100)
            }
          }
          GermanFlag: file(relativePath: { eq: "flags/german.png" }) {
            childImageSharp {
              gatsbyImageData(width: 40, quality: 100)
            }
          }
          DutchFlag: file(relativePath: { eq: "flags/dutch.png" }) {
            childImageSharp {
              gatsbyImageData(width: 40, quality: 100)
            }
          }
        }
      `
    )

  const abstractContent = abstract[0] && abstract[0].primary
  const [abstractLangContent, setAbstractLangContent] = useState(
    abstractContent.englsih.html
  )
  useEffect(() => {
    if (selectedLang === "english") {
      setAbstractLangContent(abstractContent.englsih.html)
    } else if (selectedLang === "french") {
      setAbstractLangContent(abstractContent.french.html)
    } else if (selectedLang === "spanish") {
      setAbstractLangContent(abstractContent.spanish.html)
    } else if (selectedLang === "german") {
      setAbstractLangContent(abstractContent.german.html)
    } else if (selectedLang === "dutch") {
      setAbstractLangContent(abstractContent.dutch.html)
    }
  }, [abstractLangContent, selectedLang])

  return (
    <AbstractContainer dangerouslySetInnerHTML={{ __html: abstractLangContent }} />
  )
}
const AbstractContainer = styled.div`
  & *{
    font-family: "Roboto", sans-serif!important;
    color: rgb(62,62,62);
  }
  img {
    max-width: 600px;
    display: block;
    margin: 0 auto;
  }
  p{
    font-weight: 500;
    color: rgb(62,62,62);
  }
  ul, ol {
    padding: 0;
    margin-bottom: 32px;
    list-style: inside;
  }
  h4, h5, h6 {
    margin-bottom: 32px;
    font-size: 18px;
  }
  ol li, ul li {
    line-height: 30px;
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .embed iframe {
    width: 600px;
    height: 350px;
    margin: 0 auto 20px;
    max-width: 100%;
    display: block;
  }
  @media (max-width: 1024px) {
    ul, ol {
      margin-bottom: 15px;
    }
    ol li, ul li {
      font-size: 15px;
      line-height: 25px;
    }
    h4, h5, h6 {
      margin-bottom: 15px;
      font-size: 15px;
    }
  }
  @media (max-width: 640px) {
    .embed iframe {
      height: 300px;
    }
  }
`
export default Abstract
