import React from "react"

import PropTypes from "prop-types"

import styled from "styled-components"
import { Link } from "gatsby"

const Authors = ({ authors }) => {
  return (
    <Links>
      {authors.map((author, i) => {
        const { name } = author
        return (
          <li key={i}>
            <ExploreLink>
              <Link to={`/team/${author?.linkURL?.uid}`}>
                {name}
              </Link>
            </ExploreLink>
          </li>
        )
      })}
    </Links>
  )
}

Authors.propTypes = {
  authors: PropTypes.array.isRequired,
}

export default Authors

const Links = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
`
const ExploreLink = styled.div`
  margin-left: 6px;
  @media (max-width: 640px) {
    margin-left: 0;
    margin-right: 6px;
  }
  a {
    color: #2a95bf;
    font-size: 20px;
    font-weight: 500;
    text-decoration: underline;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
`
