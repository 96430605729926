import React from 'react';
import styled from "styled-components";

const WrittenBy = ({children, black = false}) => {
  return (
    <WrittenWrapper>
      Written by:
      <AuthorWrapper>
        {children}
      </AuthorWrapper>
    </WrittenWrapper>
  );
};

const WrittenWrapper = styled.div`
  margin-bottom: 20px;
  font-weight: 600;
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  svg {
    margin-left: 10px;
  }
`
const AuthorWrapper = styled.div`
  display: flex;
  align-items: center;
`

export default WrittenBy;